<template>
   
    <v-form class="ma-12" style="width:90%">
         <confirm ref="confirm" />
        <v-text-field outlined v-model="role.name" label="Name"></v-text-field>
        <v-text-field outlined v-model="role.description" label="Beschreibung"></v-text-field>
       
            <v-layout col wrap cols="3">
            <v-checkbox
              style="width: 33%"
              v-for="p in $store.getters.permissions"
              :key="p.property"
              v-model="role.permissions"
              :value="p.property"
              :label="p.description"
              dense
              :title="p.property"
              color="secondary"
            ></v-checkbox>
          </v-layout>

        <v-row class="mt-6">
             <v-btn type="button" text color="secondary" v-if="role.id && role.id > 2" @click="deleteRole">{{ trl('delete')}}...</v-btn>
            <v-spacer></v-spacer>
            <v-btn type="button" text color="secondary" to="./">{{ trl('cancel')}}</v-btn>
            <v-btn type="submit" color="secondary" @click.prevent="saveRole">{{trl('save')}}</v-btn>
        </v-row>
        <hr class="my-12">
        <v-row  class="mt-6">
            <div v-if="usersWithRole.length > 0">
                <h3 class="mb-4">{{ usersGrouped.length }} Benutzer mit dieser Rolle</h3>
            <v-simple-table class="tu-table" >
                <tr v-for="(u, index) in usersGrouped" :key="index">
                    <td> <v-list-item-avatar color="white" ><v-img contain :src="u.img"></v-img></v-list-item-avatar></td>
                    <td><strong>{{ u.userName }}</strong></td>
                    <td>{{ u.tenants.join(", ") }}</td>
                </tr>

            </v-simple-table>
            </div>
            <v-banner class="info" style="width:100%" v-else>Keine Benutzer:innen mit dieser Rolle.</v-banner>
            <div style="width:100%" class="mt-4"><small>Rollenzuweisungen sind ausschließlich in der Benutzerverwaltung des jeweiligen Mandanten möglich.</small></div>
            
        </v-row>
    </v-form>
    
</template>

<script>
import { trl } from "@/utils/strings";
import confirm from "@/components/modals/Confirm.vue";
export default {
    props: ["adminData"],
    components: { confirm },
    data() {
        return {
            role: {
                name: "",
                description: "",
                permissions: []
            },
            usersWithRole: [],
            usersGrouped: []
        }
    },
    methods: {
        trl,
        async saveRole() {
            await this.$store.dispatch("req", {
                route: "roles",
                data: this.role,
            });
            this.$store.dispatch("req", {method: "GET", route: "roles", mutation: "roles"});
        },
        async deleteRole(){

            if (this.usersWithRole.length > 0) {
                this.$store.commit("alert", {title: "Benutzerrolle löschen", text: "Die Rolle kann erst gelöscht werden, wenn sie keinen Benutzer*innen zugewiesen ist."})
                return;
            }

            let confirmed = await this.$refs.confirm.open(
                "Benutzerrolle löschen",
                "Willst du diese Benutzerrolle löschen?",
                { confirmLabel: "Löschen", cancelLabel: "Abbrechen", color: "primary", width: 500 }
            );

            if (!confirmed) return;

            await this.$store.dispatch("req", {
                method: "DELETE",
                route: "roles",
                data: this.role,
            });

            this.$store.dispatch("req", {method: "GET", route: "roles", mutation: "roles"});
            this.$router.push('./')

    },
    },
    created(){
        const requested = this.$store.getters.roles.find(r => r.id === this.$route.params.id);
        
        let label = "Neue Benutzerrolle";
        if (requested) {
            this.role = JSON.parse(JSON.stringify(requested))
            label = requested.name;
        }

        let breadcrumbs = this.$route.matched;
        breadcrumbs.at(-1).meta.label = label;
        this.$root.$emit('messageFromChild', breadcrumbs);

        this.usersWithRole = requested ? this.adminData.users.filter(x => x.role_id === requested.id) : [];

        if (this.usersWithRole.length > 0) {
            this.usersGrouped = [];
            [...new Set(this.usersWithRole.map(u => u.id))].forEach(uid => {
                let objUser = this.$store.state.users.find(obj => obj.id === uid);
                this.usersGrouped.push({
                    user_id: uid,
                    img: objUser ? objUser.avatar : "",
                    userName: this.usersWithRole.filter(u => u.id === uid)[0].userName,
                    tenants: this.usersWithRole.filter(u => u.id === uid).map(obj => obj.tenantName)
                })
            })
            this.usersGrouped.sort((a,b) => a.userName.localeCompare(b.userName));
            
        }

    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--dense > .v-input__control {
  height: 1.25rem;
}

.tu-table{
    td{
        padding-right: 3rem;
    }
}
</style>